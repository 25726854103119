import { THEMES } from "@app/configs/theme.config";
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, map, Observable, take } from "rxjs";
import { HttpResult } from "@app/shared/models/http-result";
import { GeokPlatformConfig, GeokTheme } from "@app/configs/geokTheme";
import { ApplicationHttpClient } from "@app/helpers/custom-http-client";
import { HttpHeaders } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private configUrl = '/config';
    private themeImages = ["backgroundImage", "mainLogo", "smallLogo"];

    constructor(
        private http: ApplicationHttpClient,
        @Inject(DOCUMENT) private document: Document) {
    }

    setTheme(): Observable<GeokTheme> {
        const defaultTheme: GeokTheme = THEMES.default;
        return this.getTheme().pipe(
            take(1),
            tap((it: GeokTheme) => {
                if (it.familyFont) {
                    this.setThemeProperties(it, defaultTheme);
                } else {
                    this.setThemeProperties(defaultTheme, defaultTheme);
                }
            }),
            catchError(() => {
                this.setThemeProperties(defaultTheme, defaultTheme);
                return EMPTY;
            }),
        );
    }

    private setThemeProperties(theme: GeokTheme, defaultTheme: GeokTheme) {
        if(Object.keys(theme).length ===0) {
            theme = {
              familyFont: '',
                favicon: '',
                mainLogo: '',
                backgroundImage: '',
                cardBackgroundColor: '',
                smallLogo: '',
                buttonBorderRadius: '',
                iconButtonBorderRadius: '',
                lightThemeColor: '',
                navigationGradientOne: '',
                navigationGradientTwo: '',
                navigationIconActiveBgColor: '',
                navigationIconActiveColor: '',
                navigationTextActiveColor: '',
                navigationTextColor: '',
                outlinedBgButtonHover: '',
                outlinedTextButton: '',
                primaryBgButton: '',
                primaryBgButtonHover: '',
                primaryTextButton: '',
                primaryTextColor: '',
                screenBackgroundColor: '',
                secondaryBgButton: '',
                secondaryBgButtonHover: '',
                secondaryTextButton: '',
                secondaryTextColor: '',
                textBgButtonHover: '',
                textTextButton: '',
            };
        }

        Object.keys(theme).forEach((key) => {
            if (key === 'favIcon') {
                const faviconLink = this.document.querySelector("link[rel*='icon']") as HTMLLinkElement;
                faviconLink.href = theme[key as keyof GeokTheme];
            } else if (this.themeImages.includes(key)) {
                if (theme[key as keyof GeokTheme].length > 0) {
                    this.document.documentElement.style.setProperty(`--${key}`, `url(${theme[key as keyof GeokTheme]})`);
                }
            } else {
                if (theme[key as keyof GeokTheme].length === 0) { 
                    this.document.documentElement.style.setProperty(`--${key}`, defaultTheme[key as keyof GeokTheme]);
                } else {
                    this.document.documentElement.style.setProperty(`--${key}`, theme[key as keyof GeokTheme]);
                }
            }
        });
    }

    getTheme(): Observable<GeokTheme> {
        return this.http
            .get<HttpResult>(this.configUrl + '/themes', {
                    headers: new HttpHeaders({'bypass-interceptor': 'true'})
                }
            )
            .pipe(map((it) => it.data));
    }

    public getPlatformConfig(): Observable<GeokPlatformConfig> {
        return this.http.get<HttpResult>(this.configUrl + '/platform', {
            headers: new HttpHeaders({'bypass-interceptor': 'true'})
        }).pipe(
            map((result) => {
                return result.data;
            })
        );
    }
}
