<ng-template #projected>
  @if (contentHTML) {
    <div [innerHTML]="contentHTML"></div>
  } @else {
    <ng-content></ng-content>
  }
</ng-template>

@if (tag === 'h1') {
  <h1
    [style.fontWeight]="weight"
    [ngClass]="[classes ? classes : '', color ? 'color-' + color : '']"
  >
    <ng-container *ngTemplateOutlet="projected"></ng-container>
  </h1>
}
@if (tag === 'h2') {
  <h2
    [style.fontWeight]="weight"
    [ngClass]="[classes ? classes : '', color ? 'color-' + color : '']"
  >
    <ng-container *ngTemplateOutlet="projected"></ng-container>
  </h2>
}
@if (tag === 'h3') {
  <h3
    [style.fontWeight]="weight"
    [ngClass]="[classes ? classes : '', color ? 'color-' + color : '']"
  >
    <ng-container *ngTemplateOutlet="projected"></ng-container>
  </h3>
}
@if (tag === 'h5') {
  <h5
    [style.fontWeight]="weight"
    [ngClass]="[classes ? classes : '', color ? 'color-' + color : '']"
  >
    <ng-container *ngTemplateOutlet="projected"></ng-container>
  </h5>
}
@if (tag === 'p') {
  <p
    [style.fontWeight]="weight"
    [ngClass]="[classes ? classes : '', color ? 'color-' + color : '']"
  >
    <ng-container *ngTemplateOutlet="projected"></ng-container>
  </p>
}
@if (tag === 'small') {
  <small
    [style.fontWeight]="weight"
    [ngClass]="[classes ? classes : '', color ? 'color-' + color : '']"
  >
    <ng-container *ngTemplateOutlet="projected"></ng-container>
  </small>
}
