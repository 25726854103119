import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {
  @Input({ required: true }) rating: number = 0;
  @Input() numberOfReviews: number = 0;
  @Input() showNumber: boolean = false;

  fixedRating!: number;

  fullStarRating!: number;

  ngOnInit() {
    this.fullStarRating = Math.floor(this.rating);
    this.fixedRating = Number(this.rating.toFixed(1));
  }
}
