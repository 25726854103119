import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { GeoSnackBarComponent } from '@app/shared/components/snack-bar/snack-bar.component';
import { GeoSnackBar } from '@app/shared/models/snack-bar.model';

/** for use this service, please insert shared module */
@Injectable()
export class GeoSnackBarService {
  /**
   * Constructor
   */
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _matSnackBar: MatSnackBar,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * open basic snackbar
   *
   * @param value
   */
  basic(value: GeoSnackBar) {
    const data = {
      type: 'basic',
      title: value.title,
      message: value.message,
      dismissible: value.dismissible,
      button: value?.button,
    } as GeoSnackBar;

    this._open(data);
  }

  /**
   * open success snackbar
   *
   * @param value
   */
  success(value: GeoSnackBar) {
    const data = {
      type: 'success',
      title: value.title,
      message: value.message,
      dismissible: value.dismissible,
      button: value?.button,
    };

    this._open(data);
  }

  /**
   * open error snackbar
   *
   * @param value
   */
  error(value: GeoSnackBar) {
    const data = {
      type: 'error',
      title: value.title,
      message: value.message,
      dismissible: value.dismissible,
      button: value?.button,
    };

    this._open(data);
  }

  dismiss() {
    this._matSnackBar.dismiss();
  }

  info(value: GeoSnackBar) {
    const data = {
      type: 'info',
      title: value.title,
      message: value.message,
      dismissible: value.dismissible,
      button: value?.button,
    };
    this._open(data);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * open snack bar
   *
   * @param data
   */
  private _open(data: GeoSnackBar) {
    const panelClass = ['geo-snack-bar'];
    if (data?.button) {
      panelClass.push('geo-snack-bar--has-button');
    }
    const userConfig = {
      panelClass: panelClass,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: data.dismissible ? 99999999 : 5000,
      direction: this._document.body.getAttribute('dir'),
      data: data,
    } as MatSnackBarConfig;

    // Open the snackBar
    return this._matSnackBar.openFromComponent(
      GeoSnackBarComponent,
      userConfig,
    );
  }
}
