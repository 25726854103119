import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  addonsKey,
  AddonsState,
} from '@app/shared/store/addons/addons-reducer';
import { AddonNamesEnum } from '@app/modules/addons/models/addons.model';

export const selectAddonsState = createFeatureSelector<AddonsState>(addonsKey);

export const selectSerializedAvailableAddons = createSelector(
  selectAddonsState,
  (it: AddonsState) => it.addons.serializedAvailableAddons
);

export const selectSerializedCustomerAddons = createSelector(
  selectAddonsState,
  (it: AddonsState) => it.addons.serializedCustomerAddons
);

export const selectAllSerializedAddons = createSelector(
  selectAddonsState,
  (it: AddonsState) => it.addons
);

export const selectAllAddonsLoaded = createSelector(
  selectAddonsState,
  (it: AddonsState) => it.addonsLoaded
);

export const selectThirdPartyReviewsAddon = createSelector(
  selectAddonsState,
  (it: AddonsState) =>
    it.addons.serializedCustomerAddons.find(
      (addon) => addon.title === AddonNamesEnum.THIRD_PARTY_REVIEWS
    )
);
