import { ApplicationHttpClient } from '@app/helpers/custom-http-client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpResult } from '@app/shared/models/http-result';
import { map } from 'rxjs/operators';
import { EmailData } from '@app/shared/models/email';

@Injectable()
export class ReviewBoosterService {
  private boosterUrl = '/review-booster/list/boost';

  constructor(private http: ApplicationHttpClient) {}

  public sendEmail(email: EmailData): Observable<void> {
    const emailUrl: string = this.boosterUrl;
    return this.http
      .post<HttpResult>(emailUrl, email)
      .pipe(map((result) => result.data));
  }

  public getAiMessage(locationId: string, language: string): Observable<string> {
    const aiUrl: string = `/locations/${locationId}/ai-request-review-message?lang=${language}`;
    return this.http.get<HttpResult>(aiUrl).pipe(map((result) => result.data));
  }
}
