import { createReducer, on } from '@ngrx/store';
import {
  setSelectedBrand,
  setSelectedLocations,
  setSelectedLocationsObject,
} from '@app/modules/locations/store/locations-actions';
import { GeoKLocationLight } from '@app/shared/models/geo-k-location';

export const locationsKey = 'locations';

export interface LocationsState {
  brandId: string;
  selectedLocations: string[];
  selectedLocationsObjects: GeoKLocationLight[];
}

export const initialState: LocationsState = {
  brandId: '',
  selectedLocations: [],
  selectedLocationsObjects: [],
};
export const LocationsReducer = createReducer(
  initialState,
  on(setSelectedBrand, (state: LocationsState, { brandId }) => ({
    ...state,
    brandId: brandId,
  })),

  on(setSelectedLocations, (state: LocationsState, { locationIds }) => ({
    ...state,
    selectedLocations: locationIds,
  })),

  on(setSelectedLocationsObject, (state: LocationsState, { locations }) => ({
    ...state,
    selectedLocationsObjects: locations,
    selectedLocations: locations.map((lc) => lc.id),
  }))
);
