import {
  IAddonsSerialized,
  IGetAllAddonsResponse,
} from '@app/modules/addons/models/addons.model';
import { createAction, props } from '@ngrx/store';

export const fetchAddons = createAction('fetch addons');

export const fetchAddonsSuccess = createAction(
  'fetch addons success',
  props<{ addons: IGetAllAddonsResponse }>()
);

export const fetchAddonsFailure = createAction(
  'fetch addons failure',
  props<{ error: string }>()
);

export const setSerializedAddons = createAction(
  'set serialized addons',
  props<{ addons: IAddonsSerialized; addonsLoaded: boolean }>()
);
