import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
})
export class PillComponent {
  @Input({ required: true }) size: 'small' | 'medium' | 'large' = 'medium';
  @Input({ required: true }) color: 'primary' | 'green' | 'danger' | 'black' =
    'primary';
}
