import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { shareObservable } from '@app/helpers/rxjs-helper';
import { Brand } from '@app/shared/models/brand';
import { FilterOperator, FilterRequest } from '@app/shared/models/filter';
import {
  GeoKLocation,
  SelectLocationTableData,
} from '@app/shared/models/geo-k-location';
import {
  LocationVerificationStatus,
  ModalReturnType,
  SelectLocationDialogComponentData,
} from '@app/shared/models/locations-filters';
import {
  PageEventData,
  Pagination,
  PagingRequest,
} from '@app/shared/models/paging.class';
import { Tag } from '@app/shared/models/tag';
import { CompanyService } from '@app/shared/services/company.service';
import { ErrorService } from '@app/shared/services/error.service';
import { GeoSnackBarService } from '@app/shared/services/snack-bar/snack-bar.service';
import { UserService } from '@app/shared/services/user.service';
import { AuthFacade } from '@app/shared/store/auth/auth.facade';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  finalize,
  Observable,
  retry,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-select-location-dialog',
  templateUrl: './select-location-dialog.component.html',
  styleUrls: ['./select-location-dialog.component.scss'],
})
export class SelectLocationDialogComponent implements OnInit, OnDestroy {
  isLoading = true;
  displayedColumns: string[] = ['select', 'location', 'verified'];
  dataSource = new MatTableDataSource<SelectLocationTableData>([]);
  isMultipleSelection = !this.data.singleSelection;
  selection = new SelectionModel<string>(this.isMultipleSelection, []);
  showSelectAllLocationBanner = false;
  pageSize = 10;
  paging: Pagination = {} as Pagination;
  lastPageRequest: PagingRequest = { page: 1, limit: this.pageSize };
  filter: FilterRequest[] = [];
  currentFilter: LocationVerificationStatus = LocationVerificationStatus.ALL;
  form!: FormGroup;
  companyId!: string;
  brands: Brand[] = [];
  tags: Tag[] = [];
  private locations: GeoKLocation[] = [];
  private allLocations: GeoKLocation[] = [];
  private selectedLocationsObjects: GeoKLocation[] = [];
  locationsSubscription$: Subscription = new Subscription();
  brandsSubscription$: Subscription = new Subscription();
  tagsSubscription$: Subscription = new Subscription();
  searchSubscription$: Subscription = new Subscription();
  fetchLocationsSubscription$: Subscription = new Subscription();
  allLocationsSubscription$: Subscription = new Subscription();
  private readonly debounceTimeMs = 500;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SelectLocationDialogComponentData,
    public userService: UserService,
    private companyService: CompanyService,
    private snackBarService: GeoSnackBarService,
    public matDialogRef: MatDialogRef<SelectLocationDialogComponent>,
    private authFacade: AuthFacade,
    private fb: FormBuilder,
    private errorService: ErrorService
  ) {}

  private initFilterForm() {
    this.form = this.fb.group({
      search: null,
      brandIds: this.data?.brandId ? [this.data.brandId] : [],
      tagIds: [],
    });
  }

  ngOnInit() {
    this.initFilterForm();
    if (this.data.selectedLocations) {
      const locationsIds = this.data.selectedLocations.map((it) => it.id);
      this.selection.setSelection(...locationsIds);
      this.selectedLocationsObjects = [...this.data.selectedLocations];
    }

    if (this.data.filter) {
      this.currentFilter = this.data.filter;
    } else {
      this.filter = [];
      const length = this.data?.brandId?.length ?? 0;
      if (length != 0) {
        this.filter?.push({
          operation: FilterOperator.IN,
          property: 'brandId',
          value: this.data?.brandId as string,
        });
      }
    }
    this.fetchPaginatedLocations();
    this.loadSearchFormData();
    this.listenForSearch();
  }

  listenForSearch() {
    this.searchSubscription$ = this.form.valueChanges
      .pipe(
        debounceTime(this.debounceTimeMs),
        distinctUntilChanged(),
        tap((value) => {
          this.filter = [];
          if (value['brandIds'] && !this.data.brandId) {
            const length = (value['brandIds'] as string[]).length;
            if (length != 0) {
              this.filter?.push({
                operation: FilterOperator.IN,
                property: 'brandId',
                value: value['brandIds'] as string[],
              });
            }
          }

          if (value['tagIds']) {
            const length = (value['tagIds'] as string[]).length;
            if (length != 0) {
              this.filter?.push({
                operation: FilterOperator.IN,
                property: 'tagId',
                value: value['tagIds'],
              });
            }
          }

          if (value['search']?.trim()) {
            this.filter?.push({
              operation: FilterOperator.SEARCH,
              property: '',
              value: value['search'].trim(),
            });
          }
          this.fetchPaginatedLocations(1, this.pageSize);
        })
      )
      .subscribe();
  }

  loadSearchFormData() {
    this.brandsSubscription$ = this.userService
      .getMyBrands()
      .pipe(tap((brands: Brand[]) => (this.brands = brands)))
      .subscribe();
    this.tagsSubscription$.add(this.loadTags().subscribe());
  }

  loadTags(): Observable<Tag[]> {
    return this.authFacade.selectUserCompanyId$.pipe(
      shareObservable(),
      switchMap((companyId) => {
        this.companyId = companyId;
        return this.companyService.getAllTags(companyId);
      }),
      tap((tags: Tag[]) => {
        this.tags = tags;
      })
    );
  }

  clearFilter() {
    this.form.reset();
  }

  private getFilter(input: LocationVerificationStatus): FilterRequest[] {
    let filterByVerification!: FilterRequest[];
    const brandsFilterRequest: FilterRequest[] = [];
    switch (input) {
      case 'all-good':
        filterByVerification = [
          {
            operation: FilterOperator.EQUAL,
            property: 'verificationStatus',
            value: 'VERIFIED',
          },
        ];
        break;
      case 'blocked':
        filterByVerification = [
          {
            operation: FilterOperator.IN,
            property: 'verificationStatus',
            value: ['DISABLED', 'EXPIRED', 'SUSPENDED', 'CONFLICT', 'REQUIRED'],
          },
        ];
        break;
    }
    let total: FilterRequest[] = [];

    if (this.filter) {
      total = total.concat(this.filter);
    }

    if (this.data.brandId) {
      brandsFilterRequest.push({
        operation: FilterOperator.IN,
        property: 'brandId',
        value: [this.data.brandId],
      });
      total = total.concat(brandsFilterRequest);
    }
    if (filterByVerification) {
      total = total.concat(filterByVerification);
    }
    return total;
  }

  isAllSelected() {
    return this.dataSource.data.every((data) =>
      this.selection.selected.includes(data.id)
    );
  }

  clearAllSelections() {
    this.selection.clear();
    this.selectedLocationsObjects = [];
    this.showSelectAllLocationBanner = false;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.clearAllSelections();
      return;
    }
    if (this.paging.totalCount >= this.pageSize) {
      this.showSelectAllLocationBanner = true;
    }
    const list = this.dataSource.data.map((x) => x.id);
    if (list) {
      this.selection.select(...list);
      this.selectedLocationsObjects = [...this.locations];
    }
  }

  submitForm(): void {
    if (this.data.inViewMode) {
      return;
    }
    if (this.isMultipleSelection) {
      if (this.data.returnType === ModalReturnType.GKLOCATION) {
        this.matDialogRef.close(this.selectedLocationsObjects);
      } else {
        this.matDialogRef.close(this.selection.selected);
      }
    } else {
      const location = this.locations.find(
        (it: GeoKLocation) => it.id === this.selection.selected[0]
      );
      this.matDialogRef.close(location);
    }
  }

  fetchPaginatedLocations(page = 1, limit = this.pageSize) {
    this.isLoading = true;
    this.lastPageRequest = {
      page: page,
      limit: limit,
    } as PagingRequest;
    this.fetchLocationsSubscription$ = this.userService
      .getMyLocations(this.lastPageRequest, this.getFilter(this.currentFilter))
      .pipe(
        tap((it) => {
          this.paging = {
            totalCount: it.pagination.totalCount,
            pageIndex: it.pagination.pageIndex,
          };
          this.locations = it.items;
          this.dataSource.data = it.items.map((item) => {
            return {
              ...item,
              address:
                item.addressLine1 || item.state
                  ? [item.addressLine1, item.state].join(' - ')
                  : '',
            };
          });
        }),
        catchError((e) => {
          this.snackBarService.error({
            title: 'Error',
            message: this.errorService.getErrorMessage(e.error.message),
          });
          return EMPTY;
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  handleChangePage(pagingDetails: PageEventData) {
    this.fetchPaginatedLocations(
      pagingDetails.pageIndex,
      pagingDetails.pageSize
    );
  }

  ngOnDestroy() {
    this.locationsSubscription$.unsubscribe();
    this.fetchLocationsSubscription$.unsubscribe();
    this.brandsSubscription$.unsubscribe();
    this.tagsSubscription$.unsubscribe();
    this.searchSubscription$.unsubscribe();
    this.allLocationsSubscription$.unsubscribe();
  }

  private fetchAllLocations() {
    this.isLoading = true;
    this.allLocationsSubscription$ = this.userService
      .getMyLocations({}, this.filter)
      .pipe(
        tap((data) => {
          this.allLocations = data.items;
          this.selection.select(...this.allLocations.map((x) => x.id));
          this.selectedLocationsObjects = this.allLocations;
          this.snackBarService.basic({
            title:
              `${this.allLocations.length}` +
              `  <div>locations fetched and selected</div>`,
          });
        }),
        retry(1),
        catchError(() => {
          this.snackBarService.error({
            title: 'Failed to select locations, try again',
          });
          return EMPTY;
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  selectAllLocations() {
    if (this.paging.totalCount >= this.pageSize) {
      this.fetchAllLocations();
    }
  }

  toggleSelection(id: string) {
    const isSelected = this.selection.isSelected(id);
    const location = this.locations.find((it) => it.id === id);
    this.selection.toggle(id);
    if (!isSelected) {
      if (location) {
        this.selectedLocationsObjects.push(location);
      }
    } else {
      this.selectedLocationsObjects = this.selectedLocationsObjects.filter(
        (item) => item.id != id
      );
    }
  }
}
