import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as AddonsReducer from './addons-reducer';
import { AddonsEffects } from '@app/shared/store/addons/addons-effects';

@NgModule({
  imports: [
    StoreModule.forFeature(
      AddonsReducer.addonsKey,
      AddonsReducer.AddonsReducer
    ),
    EffectsModule.forFeature([AddonsEffects]),
  ],
})
export class AddonsStoreModule {}
