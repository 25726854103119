import { Component, Input } from '@angular/core';

type PillColor = 'primary' | 'green' | 'danger' | 'black' | 'pink' | 'cyan';
type PillSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
})
export class PillComponent {
  @Input({ required: true }) size: PillSize = 'medium';
  @Input({ required: true }) color: PillColor = 'primary';
}
