import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomFormBuilder } from '@app/shared/form-builder/form-builder-2.class';
import {
  IFormMetaData,
  IImageUploaderMetaData,
} from '@app/shared/form-builder/form-builder-2.model';
import { FieldType } from '@app/shared/form-builder/form-builder-2.model';

@Component({
  selector: 'app-form-renderer-2',
  templateUrl: './form-renderer-2.component.html',
  styleUrls: ['./form-renderer-2.component.scss'],
})
export class FormRenderer2Component implements OnInit {
  @Input({ required: true }) customFormBuilder!: CustomFormBuilder;

  @Output() formSubmitted: EventEmitter<unknown> = new EventEmitter();
  @Output() formChanged: EventEmitter<unknown> = new EventEmitter();

  public formGroup?: FormGroup;
  public formMetaData?: IFormMetaData;

  FieldType = FieldType;

  constructor() {}

  ngOnInit(): void {
    this.formMetaData = this.customFormBuilder.getFormMetadata();
    this.formGroup = this.customFormBuilder.build();
  }

  chooseImage(imageUrl: string, field: IImageUploaderMetaData) {
    field.value = imageUrl;
    this.formGroup?.controls[field.controlName]?.patchValue(imageUrl);
  }

  deleteImage(field: IImageUploaderMetaData) {
    this.formGroup?.controls[field.controlName]?.patchValue('');
  }

  submitForm(): void {
    this.formGroup?.markAllAsTouched();
    this.formSubmitted.emit(this.formGroup?.getRawValue());
  }
}
