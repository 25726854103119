import { FormGroup, ValidatorFn } from '@angular/forms';
import { DynamicImageUploaderStyle } from './data.interface';
import { CustomFormBuilder } from './form-builder-2.class';
import { EditorOptions } from 'tinymce';
import { IDropdownOption } from './form-builder.interface';

export enum FieldType {
  INPUT_TEXT = 'inputText',
  INPUT_NUMBER = 'inputNumber',
  TEXT_AREA = 'textarea',
  DROPDOWN = 'dropdown',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  DATE_PICKER = 'datePicker',
  TIME_PICKER = 'timePicker',
  IMAGE_UPLOADER = 'imageUploader',
  BUTTON = 'button',
  FORM_ARRAY = 'formArray',
  RADIO_BUTTON = 'radioButton',
  TINY_MCE = 'tinyMce',
  SLIDE_TOGGLE = 'slideToggle',
}

export type IFormMetaData = IFieldMetaData[];

export type IFieldMetaData =
  | (IInputTextMetaData & { type: FieldType.INPUT_TEXT })
  | (IInputNumberMetaData & { type: FieldType.INPUT_NUMBER })
  | (ITextareaMetaData & { type: FieldType.TEXT_AREA })
  | (IDropdownMetaData & { type: FieldType.DROPDOWN })
  | (IRadioButtonMetaData & { type: FieldType.RADIO_BUTTON })
  | (ICheckboxMetaData & { type: FieldType.CHECKBOX })
  | (IDatePickerMetaData & { type: FieldType.DATE_PICKER })
  | (ITimePickerMetaData & { type: FieldType.TIME_PICKER })
  | (IImageUploaderMetaData & { type: FieldType.IMAGE_UPLOADER })
  | (IButtonMetaData & { type: FieldType.BUTTON })
  | (IFormArrayMetaData & { type: FieldType.FORM_ARRAY })
  | (ITinyMceMetaData & { type: FieldType.TINY_MCE })
  | (ISlideToggleMetaData & { type: FieldType.SLIDE_TOGGLE });

export interface IFormFieldMetaData extends IFormFieldCommonMetaData {
  type?: FieldType;
  label: string;
  placeholder?: string;
  value?: unknown;
  displayNoneIf?: (form: FormGroup) => boolean;
}

export interface IFormArrayMetaData extends IFormFieldCommonMetaData {
  addButtonLabel?: string;
  values?: unknown[];
  fields?: IFormMetaData[];
  customBuilder?: CustomFormBuilder;
  allowRemove?: boolean;
  minRows?: number;
  maxRows?: number;
  createFields: (builder: CustomFormBuilder) => void;
  createNewRow?: () => void;
  removeRow?: (index: number) => void;
}

export interface IFormFieldCommonMetaData {
  controlName: string;
  flex?: number;
  minWidth?: string;
  validators?: ValidatorFn[];
  disabled?: boolean;
  order?: number;
  render?: boolean;
  hidden?: boolean;
  title?: string;
  hideIf?: (form: FormGroup) => boolean;
  disableIf?: (form: FormGroup) => boolean;
  renderIf?: (form: FormGroup) => boolean;
}

export interface IInputTextMetaData extends IFormFieldMetaData {
  maxLength?: number;
}

export interface IInputNumberMetaData extends IFormFieldMetaData {}

export interface ITextareaMetaData extends IFormFieldMetaData {
  maxLength?: number;
  rows?: number;
}

export interface IDropdownMetaData extends IFormFieldMetaData {
  options: { value: string | number; label: string }[];
}

export interface IRadioButtonMetaData extends IFormFieldMetaData {
  options: IDropdownOption[];
}

export interface ICheckboxMetaData extends IFormFieldMetaData {}

export interface IDatePickerMetaData extends IFormFieldMetaData {}

export interface ITimePickerMetaData extends IFormFieldMetaData {}

export interface IImageUploaderMetaData extends IFormFieldMetaData {
  viewStyle?: DynamicImageUploaderStyle;
  value: string | ArrayBuffer | null;
}

export interface IButtonMetaData extends IFormFieldMetaData {}

export interface ITinyMceMetaData extends IFormFieldMetaData {
  editorOptions: EditorOptions;
}

export interface ISlideToggleMetaData extends IFormFieldMetaData {}
