import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateService {

    getDatesBetweenDateRanges(startDate: Date, endDate: Date): Date[] {
        const dates = [];
        const currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    }

    getDate(dateData: { day: number, month: number, year: number }): Date {

        if(!dateData){
            return new Date(0, 0 ,0, 0, 0, 0, 0);
        }
        return new Date(dateData.year, dateData.month -1 , dateData.day, 0, 0, 0, 0);
    }

    getDateOrEmpty(dateData: { day: number, month: number, year: number }): string | Date {

        if(!dateData){
            return ''
        }
        return new Date(dateData.year, dateData.month -1 , dateData.day, 0, 0, 0, 0);
    }

    isTodayGreaterThanDate(expiryDate: Date): boolean {
        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);
        expiryDate.setUTCHours(0, 0, 0, 0);

        return today > expiryDate;
    }

    getDifferenceBetweenDates(startDate: Date, endDate: Date): number {
        const time1 = startDate.setUTCHours(0, 0, 0, 0);
        const time2 = endDate.setUTCHours(0, 0, 0, 0);
        const timeDifference = Math.abs(time2 - time1);
        // Convert milliseconds to days
        return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    }

}
