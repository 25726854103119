import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AssetsService } from '@app/shared/services/assets.service';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GeoSnackBarService } from '@app/shared/services/snack-bar/snack-bar.service';
import { ErrorService } from '@app/shared/services/error.service';
import { DynamicImageUploaderStyle } from '@app/shared/form-builder/data.interface';

const ASPECT_RATIO = [1.77, 1.78, 1.79];

@Component({
  selector: 'app-dynamic-image-uploader',
  templateUrl: './dynamic-image-uploader.component.html',
  styleUrls: ['./dynamic-image-uploader.component.scss'],
})
export class DynamicImageUploaderComponent implements OnInit, OnDestroy {
  @Input() viewStyle: DynamicImageUploaderStyle = 'rectangular-primary';
  @Input() imageMinSize = 7000000;
  @Input() originalImage: string | ArrayBuffer | null = '';
  @Input() isLoading = false;
  @Input() ratioImageValidation = false;
  @Input() sizeImageValidation = false;
  @Input() minWidth: number | null = null;
  @Input() minHeight: number | null = null;
  @Input() disabled = false;

  @Output() loadingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() imageChosenEmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteImage: EventEmitter<string> = new EventEmitter<string>();
  isDeleting = false;
  imagePreview: File | undefined | null;
  errorUpload = '';
  imageSubscription$: Subscription = new Subscription();
  image: string | ArrayBuffer | null = '';

  fileName: string = '';

  constructor(
    private assetsService: AssetsService,
    private snackBarService: GeoSnackBarService,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
    this.image = this.originalImage;
  }

  setImage(files: FileList) {
    this.errorUpload = '';
    const img = new Image();
    this.isDeleting = false;

    if (files?.length === 0) return;

    this.setLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(files?.item(0) as File);
    reader.onload = () => {
      img.src = window.URL.createObjectURL(files.item(0)!);

      img.onload = () => {
        if (
          (this.minWidth && img.width < this.minWidth) ||
          (this.minHeight && img.height < this.minHeight)
        ) {
          const errorMessage = `${this.minWidth !== null ? this.minWidth : ''}${this.minWidth !== null && this.minHeight !== null ? ' x ' : ''}${this.minHeight !== null ? this.minHeight : ''}`;
          this.errorUpload = `Image must be at least ${errorMessage} pixels in size`;
          this.setLoading(false);
          this.image = null;
          this.imagePreview = null;
          this.snackBarService.error({
            title: 'Error',
            message: this.errorUpload,
          });
          return;
        }

        this.image = reader.result;
        this.imagePreview = files?.item(0);
        this.fileName = this.imagePreview?.name || 'cropped-file.png';

        if (this.sizeImageValidation) {
          if (Number(this.imagePreview?.size) < this.imageMinSize) {
            this.errorUpload = `Image must be at least ${
              this.imageMinSize / 1024
            }KB`;
            this.setLoading(false);
            this.image = null;
            this.imagePreview = null;
          }
        }

        if (this.ratioImageValidation) {
          const height = img.height;
          const width = img.width;
          const aspectRatio = Number((width / height).toFixed(2));

          if (!ASPECT_RATIO.includes(aspectRatio)) {
            this.setLoading(false);
            this.imagePreview = null;
          }
        }

        if (this.imagePreview && this.errorUpload.length === 0) {
          this.uploadImage(this.imagePreview);
        }
      };
    };
  }

  uploadImage(imagePreview: File) {
    this.imageSubscription$ = this.assetsService
      .uploadFile(imagePreview)
      .pipe(
        tap((imageId: string) => {
          this.setLoading(false);
          this.imageChosenEmit.emit(imageId);
        }),
        catchError((e) => {
          this.setLoading(false);
          this.errorUpload = '';

          if (this.originalImage) {
            this.image = this.originalImage;
          } else {
            this.image = null;
            this.imagePreview = null;
          }
          this.snackBarService.error({
            title: 'Error',
            message: this.errorService.getErrorMessage(e.error.message),
          });
          return EMPTY;
        })
      )
      .subscribe();
  }

  removePhoto() {
    this.isDeleting = true;
    this.image = null;
    this.imagePreview = null;

    this.deleteImage.emit('');
  }

  private setLoading(value: boolean) {
    this.isLoading = value;
    this.loadingEmitter.emit(value);
  }

  ngOnDestroy() {
    this.imageSubscription$.unsubscribe();
  }
}
