import { CountryPlan } from '@app/shared/models/country-plan';

export interface IGetAllAddonsResponse {
  customerProducts: ICustomerProduct[] | null;
  availableProducts: IAvailableAddonDetails[] | null;
}

export interface IDynamicProductCostResponse {
  cost: number;
  quantity: number;
  yearlyCost: number;
}

export interface ICustomerProduct {
  id: string;
  packageID: string;
  availableQuota: number;
  expiryDate: string;
  quantity: number;
  status: AddonStatusEnum;
  package: ICustomerPackage;
}

export interface IAddonPackage {
  id: string;
  priority: number;
  quota: number;
  price: number;
  popular: boolean;
  freemium: boolean;
  productID: string;
  package: AddonPackagePlanEnum;
}

export interface ICustomerPackage extends IAddonPackage {
  product: IAddonDetails;
}

export interface IAddonDetails {
  id: string;
  title: string;
  description: string;
  short_description: string;
  unit: string;
  icon: string;
  active: boolean;
  type: AddonTypeEnum;
  countryPricing: CountryPlan;
}

export interface IAvailableAddonDetails extends IAddonDetails {
  status: boolean;
  packages: IAddonPackage[];
}

export interface ICustomerAddonPackage extends IAddonPackage {
  product: IAddonDetails;
}

export interface IActivateAddonPost {
  packageId: string;
  redirectUrl: string;
  lang: string;
  quantity: number;
}

export interface IAddonsSerialized {
  serializedCustomerAddons: ICustomerAddonSerialized[];
  serializedAvailableAddons: IAvailableAddonSerialized[];
}

export type IAddonSerialized =
  | (ICustomerAddonSerialized & {
      addonAvailability: AddonAvailabilityEnum.ACTIVE_PRODUCT;
    })
  | (IAvailableAddonSerialized & {
      addonAvailability: AddonAvailabilityEnum.AVAILABLE_PRODUCT;
    });

export interface IAddonSerializedCommonData {
  addonId: string;
  title: string;
  short_description: string;
  unit: string;
  icon: string;
  description: string;
}
export interface ICustomerAddonSerialized extends IAddonSerializedCommonData {
  active: boolean;
  status: AddonStatusEnum;
  availableQuota: number;
  quantity: number;
  expiryDate: string;
  currency: string;
  type: AddonTypeEnum;
  packageId: string;
  price: number;
  packagePlanType: AddonPackagePlanEnum;
  quota: number;
  productId: string;
  customerProductId: string;
  addonAvailability: AddonAvailabilityEnum.ACTIVE_PRODUCT;
}

export interface IAvailableAddonSerialized extends IAddonSerializedCommonData {
  packages: IAddonPackage[];
  currency: string;
  type: AddonTypeEnum;
  addonAvailability: AddonAvailabilityEnum.AVAILABLE_PRODUCT;
}

export enum AddonPackagePlanEnum {
  BASIC = 'Basic',
  ADVANCED = 'Advanced',
  PREMIUM = 'Premium',
}

export enum AddonTypeEnum {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
}

export enum AddonStatusEnum {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  PAUSED = 'PAUSED',
}

export enum AddonAvailabilityEnum {
  ACTIVE_PRODUCT = 'ACTIVE_PRODUCT',
  AVAILABLE_PRODUCT = 'AVAILABLE_PRODUCT',
}

export enum AddonNamesEnum {
  THIRD_PARTY_REVIEWS = 'Third Party Reviews',
}
