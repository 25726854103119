import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  selectSerializedAvailableAddons,
  selectSerializedCustomerAddons,
  selectAllSerializedAddons,
  selectAllAddonsLoaded,
  selectThirdPartyReviewsAddon,
} from './addons-selector';
import { AddonsState } from './addons-reducer';
import { fetchAddons } from './addons-actions';

@Injectable({
  providedIn: 'root',
})
export class AddonsFacade {
  constructor(private store: Store<AddonsState>) {}

  selectSerializedAvailableAddons$ = this.store.pipe(
    select(selectSerializedAvailableAddons)
  );

  selectSerializedCustomerAddons$ = this.store.pipe(
    select(selectSerializedCustomerAddons)
  );

  selectSerializedAllAddons$ = this.store.pipe(
    select(selectAllSerializedAddons)
  );

  selectAllAddonsLoaded$ = this.store.pipe(select(selectAllAddonsLoaded));

  selectThirdPartyReviewsAddon$ = this.store.pipe(
    select(selectThirdPartyReviewsAddon)
  );

  fetchAddons() {
    this.store.dispatch(fetchAddons());
  }
}
