<ng-template #projected>
  <ng-content></ng-content>
</ng-template>

@if (isIcon) {
  <button
    (click)="clicked.emit()"
    mat-icon-button
    [ngClass]="[
      'button button-icon',
      'button-' + color,
      classes,
      isLoading ? 'button-loading' : '',
    ]"
    [disabled]="disabled"
    [style.minWidth]="minWidth"
    [style.minHeight]="minHeight"
    [style.width]="width"
    [style.maxWidth]="maxWidth"
    [type]="type"
    [class.button-loading]="isLoading"
  >
    <div class="button-content">
      <ng-container *ngTemplateOutlet="projected"></ng-container>
    </div>
    <div class="loader" *ngIf="isLoading"></div>
  </button>
} @else {
  <button
    (click)="clicked.emit()"
    mat-button
    [ngClass]="[
      'button',
      'button-' + size,
      'button-' + color,
      active ? 'button-active' : '',
      classes,
      isLoading ? 'button-loading' : '',
    ]"
    [disabled]="disabled"
    [style.minWidth]="minWidth"
    [style.minHeight]="minHeight"
    [style.width]="width"
    [style.maxWidth]="maxWidth"
    [type]="type"
  >
    <div class="button-content">
      <ng-container *ngTemplateOutlet="projected"></ng-container>
    </div>
    <div class="loader" *ngIf="isLoading"></div>
  </button>
}
