@if (viewStyle === 'rectangular-primary') {
  <div
    class="rectangular-primary"
    appDragDropFileUpload
    (fileDropped)="setImage($any($event))"
  >
    <!-- IMAGE = FALSE - Show add image box -->
    <div class="input-image-placeholder" *ngIf="!image">
      <app-icon
        [iconName]="'add_photo_alternate'"
        width="70px"
        height="70px"
      ></app-icon>

      <div class="my-25px text-center">
        <p class="mb-10px">Drop your image here to upload</p>

        @if (!disabled) {
          <app-button
            size="small"
            color="secondary-outlined-white"
            (clicked)="fileInput.click()"
          >
            Select Image
          </app-button>
        }
      </div>

      <input
        [disabled]="isLoading"
        hidden
        type="file"
        accept="image/jpeg, image/png"
        (change)="setImage($any($event).target.files)"
        #fileInput
      />

      <div class="requirements">
        <small *ngIf="minWidth || minHeight">
          Photo must be at least
          <ng-container *ngIf="minWidth"
            >{{ minWidth }} pixels in width</ng-container
          >
          <ng-container *ngIf="minWidth && minHeight"> and </ng-container>

          <ng-container *ngIf="minHeight"
            >{{ minHeight }} pixels in height</ng-container
          >
        </small>

        <small>Supports: JPG, JPEG, PNG</small>
      </div>
    </div>

    <!-- IMAGE = TRUE - Image preview -->
    <div class="image-preview" *ngIf="!!image">
      <div class="image-wrapper">
        <img [src]="image" alt="your photo" />
      </div>

      @if (!disabled) {
        <app-button
          class="delete-button"
          (clicked)="removePhoto()"
          size="small"
          color="black"
          [isIcon]="true"
        >
          <app-icon
            [iconName]="'delete-new'"
            [width]="'18px'"
            [height]="'auto'"
          ></app-icon>
        </app-button>
      }
    </div>
  </div>
}

@if (viewStyle === 'icon-uploader') {
  <div class="icon-uploader">
    <div class="icon-preview">
      <app-text [tag]="'h5'" [weight]="'bold'">Icon:</app-text>
      @if (image) {
        <img [src]="image" alt="Icon" />
      } @else {
        <app-text [tag]="'p'">No icon selected</app-text>
      }
    </div>

    <app-button
      (clicked)="fileInput.click()"
      size="medium"
      color="secondary-outlined-white"
    >
      @if (image) {
        Change Icon
      } @else {
        Select Icon
      }
    </app-button>

    <input
      [disabled]="isLoading"
      hidden
      type="file"
      accept="image/jpeg, image/png"
      (change)="setImage($any($event).target.files)"
      #fileInput
    />

    <div class="requirements">
      <small>Photo must be at least 400 x 300 pixels in size</small>
      <small>Supports: JPG, JPEG, PNG</small>
    </div>
  </div>
}
