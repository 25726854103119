<ng-template #projected>
  <ng-content></ng-content>
</ng-template>

<div class="pill" [ngClass]="['pill-' + size, 'pill-' + color]">
  @if (size === 'small') {
    <app-text [tag]="'small'" [color]="'inherit'">
      <ng-container *ngTemplateOutlet="projected"></ng-container>
    </app-text>
  } @else if (size === 'medium') {
    <app-text [tag]="'small'" [color]="'inherit'">
      <ng-container *ngTemplateOutlet="projected"></ng-container>
    </app-text>
  } @else if (size === 'large') {
    <app-text [tag]="'p'" [color]="'inherit'">
      <ng-container *ngTemplateOutlet="projected"></ng-container>
    </app-text>
  }
</div>
