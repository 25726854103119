import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PageEventData } from '@app/shared/models/paging.class';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagingComponent implements OnInit, OnChanges {
  @Input() pageIndex = 1;
  @Input() totalCount = 1;
  @Input() pageSize = 10;
  @Input() cursor = '';
  @Output() changePage: EventEmitter<PageEventData> =
    new EventEmitter<PageEventData>();

  isPreviousPageDisabled = true;
  isNextPageDisabled = true;
  totalNumberOfPages = 1;

  ngOnInit() {
    this.totalNumberOfPages = this.getNumberOfPages();
    this.isPreviousPageDisabled = !this.hasPreviousPage();
    this.isNextPageDisabled = !this.hasNextPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['totalCount'] && !changes['totalCount'].firstChange) {
        this.totalNumberOfPages = this.getNumberOfPages();
      }
      this.isPreviousPageDisabled = !this.hasPreviousPage();
      this.isNextPageDisabled = !this.hasNextPage();
    }
  }

  nextPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex++;
    this.emitPageEvent(previousPageIndex);
  }

  previousPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.pageIndex - 1;
    this.emitPageEvent(previousPageIndex);
  }

  firstPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = 1;
    this.emitPageEvent(previousPageIndex);
  }

  lastPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    const previousPageIndex = this.pageIndex;
    this.pageIndex = this.totalNumberOfPages;
    this.emitPageEvent(previousPageIndex);
  }

  hasPreviousPage(): boolean {
    return this.pageIndex > 1 && this.pageSize != 0;
  }

  hasNextPage(): boolean {
    const maxPageIndex = this.totalNumberOfPages;
    const it = this.pageIndex < maxPageIndex && this.pageSize !== 0;
    return it;
  }

  getNumberOfPages(): number {
    if (!this.pageSize) {
      return 0;
    }
    return Math.ceil(this.totalCount / this.pageSize);
  }

  private emitPageEvent(previousPageIndex: number) {
    this.changePage.emit({
      previousPageIndex,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.totalCount,
      cursor: this.cursor,
    });
  }
}
