import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  fetchAddons,
  fetchAddonsFailure,
  fetchAddonsSuccess,
  setSerializedAddons,
} from './addons-actions';
import { AddonsService } from '@app/modules/addons/services/addons.service';
import { of } from 'rxjs';
import { AuthFacade } from '../auth/auth.facade';

@Injectable()
export class AddonsEffects {
  constructor(
    private actions$: Actions,
    private addonsService: AddonsService,
    private authFacade: AuthFacade
  ) {}

  fetchAddons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAddons),
      switchMap(() => this.authFacade.selectUserCompanyId$),
      switchMap((companyId) =>
        this.addonsService.getAllAddons(companyId).pipe(
          map((addons) => {
            return fetchAddonsSuccess({ addons });
          }),
          catchError((err) => {
            return of(fetchAddonsFailure({ error: err.message }));
          })
        )
      )
    )
  );

  fetchAddonsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAddonsSuccess),
      map((action) => {
        const serializedCustomerAddons =
          action.addons.customerProducts?.map((it) => {
            return this.addonsService.serializeCustomerAddon(it);
          }) || [];

        const serializedAvailableAddons =
          action.addons.availableProducts?.map((it) => {
            return this.addonsService.serializeAvailableAddon(it);
          }) || [];

        return setSerializedAddons({
          addons: {
            serializedCustomerAddons,
            serializedAvailableAddons,
          },
          addonsLoaded: true,
        });
      })
    )
  );
}
