<div class="rating-line-container">
  <ng-container *ngIf="fixedRating === 0; else withRating">
    No Reviews
  </ng-container>

  <ng-template #withRating>
    <span *ngIf="showNumber" class="notranslate">{{ fixedRating }}</span>

    <div class="rating-stars">
      <ng-container *ngFor="let item of [].constructor(fullStarRating)">
        <mat-icon class="rating">star</mat-icon>
      </ng-container>

      <mat-icon
        *ngIf="fixedRating % 1 >= 0.5"
        class="rating mat-icon-rtl-mirror"
        >star_half</mat-icon
      >
    </div>
  </ng-template>
</div>
