<div class="navigation-wrapper">
  <div class="navigation-header">
    <div class="logo-thin">
      <img routerLink="/dashboard" class="d-sm-none" alt="Logo image" />
    </div>
  </div>
  
  <div
    class="aside-item-wrapper"
    *ngFor="let item of navigationItems; let i = index; trackBy: trackByFn"
  >
    <app-navigation-aside-item
      [item]="item"
      [active]="i === activeAsideIndex"
      (click)="item.onClick ? item.onClick() : toggleMenuPreview(i)"
    ></app-navigation-aside-item>
  </div>
</div>

<ng-container *ngIf="!!navigationItems[activeAsideIndex] && opened">
  <div
    class="navigation-aside-wrapper"
    [@fadeIn]="opened"
    [@fadeOut]="!opened"
    [id]="activeAsideIndex"
  >
    <app-menu-preview
      [navItem]="navigationItems[activeAsideIndex]"
    ></app-menu-preview>
  </div>

  <div
    class="navigation-aside-close"
    (click)="asideClose()"
    [attr.aria-hidden]="true"
  >
    <mat-icon class="mat-icon-rtl-mirror">navigate_before</mat-icon>
  </div>
</ng-container>
