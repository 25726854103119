import { createReducer, on } from '@ngrx/store';
import {
  setSerializedAddons,
  fetchAddonsSuccess,
  fetchAddonsFailure,
} from './addons-actions';
import { IAddonsSerialized } from '@app/modules/addons/models/addons.model';

export const addonsKey = 'addons';

export interface AddonsState {
  addons: IAddonsSerialized;
  addonsLoaded: boolean;
}

export const initialState: AddonsState = {
  addons: {
    serializedAvailableAddons: [],
    serializedCustomerAddons: [],
  },
  addonsLoaded: false,
};

export const AddonsReducer = createReducer(
  initialState,
  on(setSerializedAddons, (state: AddonsState, { addons }) => ({
    ...state,
    addons,
    addonsLoaded: true,
  })),
  on(fetchAddonsSuccess, (state: AddonsState) => ({
    ...state,
  })),
  on(fetchAddonsFailure, (state: AddonsState) => ({
    ...state,
  }))
);
