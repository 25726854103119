<ng-container *ngIf="!currentCropperImage">
  <div
    class="image-input {{ imageContainer }}"
    [class.no-border]="!!image"
    appDragDropFileUpload
    (fileDropped)="setImage($any($event))"
  >
    <!-- IMAGE = FALSE - Show add image box -->
    <div *ngIf="!image">
      <mat-icon svgIcon="add_photo_alternate"></mat-icon>
      <div class="input-image-placeholder">
        <p>Drop your image here, or</p>
        <button type="button" class="button-link" (click)="fileInput.click()">
          browse
        </button>

        <input
          [disabled]="isLoading"
          hidden
          type="file"
          accept="image/jpeg, image/png"
          (change)="setImage($any($event).target.files)"
          #fileInput
        />
      </div>
      <span class="image-support">Supports: JPG, JPEG, PNG</span>
    </div>

    <!-- IMAGE = TRUE - Image preview -->
    <ng-container *ngIf="!!image">
      <div class="image-preview" (click)="fileInput.click()">
        <input
          [disabled]="isLoading"
          type="file"
          title="click to change image"
          accept="image/jpeg, image/png"
          (change)="setImage($any($event).target.files)"
          #fileInput
        />

        <img [src]="image" alt="your photo" />

        <div class="upload-loader" *ngIf="isLoading">
          <div class="loader-icon"></div>
          <p>{{ isDeleting ? 'Deleting' : 'Uploading' }}</p>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="error" *ngIf="errorUpload">
    <p>{{ errorUpload }}</p>
  </div>

  <div class="actions">
    <button [disabled]="isLoading" class="secondary-button" mat-dialog-close>
      Cancel
    </button>

    <button
      [disabled]="isLoading"
      *ngIf="!!image"
      class="primary-outlined-button"
      (click)="removePhoto()"
    >
      Delete
    </button>
  </div>
</ng-container>

<ng-container *ngIf="currentCropperImage">
  <div class="cropper-wrapper">
    <image-cropper
      [aspectRatio]="16 / 9"
      [resizeToWidth]="2120"
      [onlyScaleDown]="true"
      [imageFile]="currentCropperImage"
      (imageCropped)="imageCropped($event)"
      format="png"
    ></image-cropper>

    <div class="upload-loader" *ngIf="isLoading">
      <div class="loader-icon"></div>
      <p>{{ isDeleting ? 'Deleting' : 'Uploading' }}</p>
    </div>
  </div>

  <div class="cropper-desc">
    <p>
      Image size should be at least
      <span class="notranslate">{{
        this.imageMinSize / 1024 | numberTranslate
      }}</span
      >KB.
    </p>
    <p
      [class.text-red]="imageMinSize > croppedImageSize.bytes"
      *ngIf="croppedImage?.blob?.size"
      class="cropper-current-img-size"
    >
      Current image size:
      <span class="notranslate">{{
        croppedImageSize.kb | numberTranslate
      }}</span
      >KB.
    </p>
  </div>

  <div class="actions">
    <button [disabled]="isLoading" class="secondary-button" mat-dialog-close>
      Cancel
    </button>

    <button
      class="primary-outlined-button"
      [disabled]="isCropperUploadButtonDisabled"
      (click)="uploadCroppedImage()"
    >
      Upload
    </button>
  </div>
</ng-container>
