@if (formGroup && formMetaData) {
  <form class="form-renderer" [formGroup]="formGroup" (ngSubmit)="submitForm()">
    @for (field of formMetaData; track field.controlName) {
      <div
        [hidden]="field.hidden"
        [style.minWidth]="field.minWidth"
        *ngIf="field.render"
      >
        @if (field.type === FieldType.INPUT_TEXT) {
          <mat-form-field class="default">
            <mat-label>{{ field.label }}</mat-label>
            <input
              matInput
              [formControlName]="field.controlName"
              [placeholder]="field.placeholder || ''"
              [maxlength]="field.maxLength || null"
            />
          </mat-form-field>
        }

        @if (field.type === FieldType.INPUT_NUMBER) {
          <mat-form-field class="default">
            <mat-label>{{ field.label }}</mat-label>
            <input
              type="number"
              matInput
              [formControlName]="field.controlName"
              [placeholder]="field.placeholder || ''"
            />
          </mat-form-field>
        }

        @if (field.type === FieldType.TEXT_AREA) {
          @if (field.title) {
            <app-text
              [classes]="'field-title'"
              [tag]="'h5'"
              [weight]="'bold'"
              >{{ field.title }}</app-text
            >
          }

          <mat-form-field class="default-material default">
            <textarea
              matInput
              [formControlName]="field.controlName"
              [placeholder]="field.placeholder || ''"
              [rows]="field.rows || 5"
              [maxlength]="field.maxLength || null"
            ></textarea>

            @if (field.maxLength) {
              <mat-hint>
                <app-text [tag]="'p'">
                  <span class="notranslate"
                    >{{
                      formGroup.controls[field.controlName].value?.length || 0
                        | numberTranslate
                    }}
                    / {{ field.maxLength | numberTranslate }}
                  </span>
                  <span>characters</span>
                </app-text>
              </mat-hint>
            }
          </mat-form-field>
        }

        @if (field.type === FieldType.IMAGE_UPLOADER) {
          <app-dynamic-image-uploader
            [viewStyle]="field.viewStyle || 'rectangular-primary'"
            [originalImage]="field.value"
            (imageChosenEmit)="chooseImage($event, field)"
            (deleteImage)="deleteImage(field)"
          ></app-dynamic-image-uploader>
        }

        @if (field.type === FieldType.RADIO_BUTTON) {
          <div class="radio-button-group-container">
            @if (field.label) {
              <app-text
                [classes]="'radio-group-label'"
                [tag]="'h5'"
                [weight]="'bold'"
                >{{ field.label | capitalizeFirst }}</app-text
              >
            }

            <mat-radio-group
              [formControlName]="field.controlName"
              [color]="'primary'"
              [value]="field.value"
            >
              @for (option of field.options; track option) {
                <mat-radio-button [value]="option.value">
                  <app-text [tag]="'p'">{{
                    option.label | capitalizeFirst
                  }}</app-text>
                </mat-radio-button>
              }
            </mat-radio-group>
          </div>
        }

        @if (field.type === FieldType.DROPDOWN) {
          <mat-form-field class="default">
            <mat-label>{{ field.label }}</mat-label>
            <mat-select
              [formControlName]="field.controlName"
              [placeholder]="field.placeholder || ''"
            >
              @for (option of field.options; track option) {
                <mat-option [value]="option.value">
                  {{ option.label | capitalizeFirst }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }

        @if (field.type === FieldType.SLIDE_TOGGLE) {
          <mat-slide-toggle
            [formControlName]="field.controlName"
            [checked]="field.value"
          >
            {{ field.label | capitalizeFirst }}
          </mat-slide-toggle>
        }

        @if (field.type === FieldType.TINY_MCE) {
          @if (field.label) {
            <app-text [tag]="'h5'" [weight]="'bold'" [classes]="'field-title'">
              {{ field.label | capitalizeFirst }}
            </app-text>
          }

          <editor
            [formControlName]="field.controlName"
            [init]="field.editorOptions"
          ></editor>
        }

        @if (field.type === FieldType.FORM_ARRAY) {
          <div class="form-array-container">
            <div class="form-array-header">
              @if (field.title) {
                <app-text [tag]="'h5'" [weight]="'bold'">
                  {{ field.title | capitalizeFirst }}
                </app-text>
              }

              @if (field.addButtonLabel) {
                <app-button
                  [size]="'medium'"
                  [color]="'secondary-outlined-white'"
                  (clicked)="field.createNewRow?.()"
                  [disabled]="
                    field.maxRows !== undefined &&
                    $any(field.fields?.length) >= field.maxRows
                  "
                >
                  {{ field.addButtonLabel }}
                </app-button>
              }
            </div>

            <div [formArrayName]="field.controlName" class="form-array-rows">
              @if (!field.fields?.length) {
                <app-text [tag]="'p'">No items yet!</app-text>
              } @else {
                @for (arrItem of field.fields; let i = $index; track i) {
                  <div [formGroupName]="i" class="form-array-row">
                    @for (arrItemField of arrItem; track arrItemField) {
                      <div
                        class="form-array-field"
                        [style.minWidth]="arrItemField.minWidth"
                        [hidden]="arrItemField.hidden"
                        [style.order]="
                          arrItemField.order ? arrItemField.order : ''
                        "
                      >
                        @if (arrItemField.type === FieldType.INPUT_TEXT) {
                          <mat-form-field class="default">
                            <mat-label>{{ arrItemField.label }}</mat-label>

                            <input
                              matInput
                              [formControlName]="arrItemField.controlName"
                              [placeholder]="arrItemField.placeholder || ''"
                              [maxlength]="arrItemField.maxLength || null"
                            />
                          </mat-form-field>
                        }

                        @if (arrItemField.type === FieldType.INPUT_NUMBER) {
                          <mat-form-field class="default">
                            <mat-label>{{ arrItemField.label }}</mat-label>
                            <input
                              type="number"
                              matInput
                              [formControlName]="arrItemField.controlName"
                              [placeholder]="arrItemField.placeholder || ''"
                            />
                          </mat-form-field>
                        }

                        @if (arrItemField.type === FieldType.TEXT_AREA) {
                          @if (arrItemField.title) {
                            <app-text
                              [classes]="'field-title'"
                              [tag]="'h5'"
                              [weight]="'bold'"
                              >{{ arrItemField.title }}</app-text
                            >
                          }

                          <mat-form-field class="default-material default">
                            <textarea
                              matInput
                              [formControlName]="arrItemField.controlName"
                              [placeholder]="arrItemField.placeholder || ''"
                              [rows]="arrItemField.rows || 5"
                              [maxlength]="arrItemField.maxLength || null"
                            ></textarea>

                            @if (arrItemField.maxLength) {
                              <mat-hint>
                                <span class="description-counter">
                                  <span class="notranslate"
                                    >{{
                                      formGroup.controls[
                                        arrItemField.controlName
                                      ].value?.length || 0 | numberTranslate
                                    }}
                                    /
                                    {{
                                      arrItemField.maxLength | numberTranslate
                                    }}
                                  </span>
                                  <span>characters</span>
                                </span>
                              </mat-hint>
                            }
                          </mat-form-field>
                        }

                        @if (arrItemField.type === FieldType.IMAGE_UPLOADER) {
                          <app-dynamic-image-uploader
                            [viewStyle]="
                              arrItemField.viewStyle || 'rectangular-primary'
                            "
                            [originalImage]="arrItemField.value"
                            (imageChosenEmit)="
                              chooseImage($event, arrItemField)
                            "
                            (deleteImage)="deleteImage(arrItemField)"
                          ></app-dynamic-image-uploader>
                        }

                        @if (arrItemField.type === FieldType.RADIO_BUTTON) {
                          <div class="radio-button-group-container">
                            @if (arrItemField.label) {
                              <app-text
                                [classes]="'radio-group-label'"
                                [tag]="'h5'"
                                [weight]="'bold'"
                                >{{ arrItemField.label }}</app-text
                              >
                            }

                            <mat-radio-group
                              [formControlName]="arrItemField.controlName"
                              [color]="'primary'"
                              [value]="arrItemField.value"
                            >
                              @for (
                                option of arrItemField.options;
                                track option
                              ) {
                                <mat-radio-button [value]="option.value">
                                  <app-text [tag]="'p'">{{
                                    option.label
                                  }}</app-text>
                                </mat-radio-button>
                              }
                            </mat-radio-group>
                          </div>
                        }

                        @if (arrItemField.type === FieldType.DROPDOWN) {
                          <mat-form-field class="default">
                            <mat-label>{{ arrItemField.label }}</mat-label>
                            <mat-select
                              [formControlName]="arrItemField.controlName"
                              [placeholder]="arrItemField.placeholder || ''"
                            >
                              @for (
                                option of arrItemField.options;
                                track option
                              ) {
                                <mat-option [value]="option.value">
                                  {{ option.label }}
                                </mat-option>
                              }
                            </mat-select>
                          </mat-form-field>
                        }

                        @if (arrItemField.type === FieldType.SLIDE_TOGGLE) {
                          <mat-slide-toggle
                            [formControlName]="arrItemField.controlName"
                            [checked]="arrItemField.value"
                          >
                            {{ arrItemField.label }}
                          </mat-slide-toggle>
                        }

                        @if (arrItemField.type === FieldType.TINY_MCE) {
                          @if (arrItemField.label) {
                            <app-text
                              [classes]="'field-title'"
                              [tag]="'h5'"
                              [weight]="'bold'"
                              >{{ arrItemField.label }}</app-text
                            >
                          }

                          <app-tiny-mce
                            [formControlName]="arrItemField.controlName"
                          ></app-tiny-mce>
                        }
                      </div>
                    }

                    @if (field.allowRemove && field.fields?.length) {
                      <app-button
                        [size]="'small'"
                        [isIcon]="true"
                        [color]="'transparent'"
                        (clicked)="field.removeRow?.(i)"
                        class="form-array-remove-button"
                      >
                        <app-icon
                          [iconName]="'delete-new'"
                          [color]="'dark-blue'"
                          [width]="'18px'"
                        ></app-icon>
                      </app-button>
                    }
                  </div>
                }
              }
            </div>
          </div>
        }
      </div>
    }
  </form>
}
