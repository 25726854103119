import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], path: string): any[] {
    if (!Array.isArray(array)) {
      return array;
    }
    return array.sort((a, b) => {
      const aValue = path.split('.').reduce((obj, key) => obj?.[key], a);
      const bValue = path.split('.').reduce((obj, key) => obj?.[key], b);
      return (aValue ?? 0) - (bValue ?? 0);
    });
  }
}
