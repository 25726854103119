import { Component, EventEmitter, Input, Output } from '@angular/core';

type ButtonSize = 'small' | 'medium' | 'large' | 'icon';

type ButtonColor =
  | 'primary'
  | 'secondary-outlined'
  | 'secondary-outlined-white'
  | 'elevated'
  | 'transparent'
  | 'black'
  | 'green'
  | 'pink'
  | 'gradient'
  | 'danger';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input({ required: true }) size: ButtonSize = 'medium';
  @Input({ required: true }) color: ButtonColor = 'primary';
  @Input() isLoading: boolean = false;
  @Input() classes: string = '';
  @Input() disabled: boolean = false;
  @Input() isIcon: boolean = false;
  @Input() type: 'button' | 'submit' = 'button';
  @Input() minWidth: string = '';
  @Input() width: string = '';
  @Input() maxWidth: string = '';
  @Input() minHeight: string = '';
  @Input() elevation: 'gk-elevation-1' | '' = '';
  @Input() active?: boolean;

  @Output() clicked: EventEmitter<void> = new EventEmitter();
}
