import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], path: string): any[] {
    if (!Array.isArray(array) || !path) {
      return array;
    }

    const clonedArray = [...array];

    return clonedArray.sort((a, b) => {
      const aValue = this.getValueByPath(a, path);
      const bValue = this.getValueByPath(b, path);

      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }
      return 0;
    });
  }

  private getValueByPath(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
}
