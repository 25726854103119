import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@app/core/components/not-found/not-found.component';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { RoleAccessResolver } from '@app/shared/guards/role-access.resolver';
import { privateAuth } from '@app/shared/guards/auth.guard';
import {
  ALL_MEMBERS,
  UserAccessRolesEnum,
} from '@app/shared/models/user/user-access';
import { PlanAccessResolver } from '@app/shared/guards/plan-access.resolver';
import { PlanTypeEnum } from '@app/shared/models/payment-result';
import { environment } from '@environments/environment';
import { PaymentGateway } from './configs/geokTheme';
import { PaymentGatewayAccessGuard } from './shared/guards/payment-gateway.guard';
import { NoPermissionComponent } from './core/components/no-permission/no-permission.component';

export const ROUTE_PATHS = {
  NOT_FOUND: '**',
  AUTH: 'auth',
  MAIN_DASHBOARD: 'dashboard',
  ON_BOARDING: 'onboarding',
  SOCIAL_POSTING: 'social-posting',
  LOCATIONS: 'locations',
  REVIEWS: 'reviews',
  USER_PROFILE: 'user-profile',
  BRANDS: 'brands',
  TAGS: 'tags',
  TEAM_MANAGEMENT: 'team-management',
  SUBSCRIPTION: 'subscription',
  ADDONS: 'addons',
  BI_DASHBOARD: 'dashboards',
  COMPETITORS: 'competitors',
  COMPANY_INFO_ROUTING: 'company-info',
  NO_PERMISSION: 'no-permission',
};
// TODO: add IsDoneOnboardingGuard once skip for now have logic
const env = JSON.parse(JSON.stringify(environment));

const NOT_FOUND_ROUTE: Route = {
  path: ROUTE_PATHS.NOT_FOUND,
  component: NotFoundComponent,
};

const DEFAULT_ROUTING: Route = {
  path: '',
  redirectTo: ROUTE_PATHS.AUTH,
  pathMatch: 'full',
};

const SUBSCRIPTION_ROUTING: Route = {
  path: ROUTE_PATHS.SUBSCRIPTION,
  canActivate: [AngularFireAuthGuard],
  resolve: { currentUserRoles: RoleAccessResolver },
  data: {
    authGuardPipe: privateAuth,
    access: [UserAccessRolesEnum.COMPANY_ADMIN],
  },

  loadChildren: () =>
    import('@app/modules/subscription/subscription.module').then(
      (m) => m.SubscriptionModule
    ),
};

const ADDONS_ROUTING: Route = {
  path: ROUTE_PATHS.ADDONS,
  canActivate: [AngularFireAuthGuard],
  resolve: { currentUserRoles: RoleAccessResolver },
  data: { authGuardPipe: privateAuth, access: ALL_MEMBERS },
  loadChildren: () =>
    import('@app/modules/addons/addons.module').then((m) => m.AddonsModule),
};

const BI_DASHBOARD_ROUTING: Route = {
  path: ROUTE_PATHS.BI_DASHBOARD,
  canActivate: [AngularFireAuthGuard],
  resolve: { currentUserRoles: RoleAccessResolver },
  data: {
    authGuardPipe: privateAuth,
    access: [
      UserAccessRolesEnum.COMPANY_ADMIN,
      UserAccessRolesEnum.BRAND_ADMIN,
      UserAccessRolesEnum.ACCESS_GROUP_SOCIAL_MANAGER,
      UserAccessRolesEnum.BRAND_SOCIAL_MANAGER,
    ],
  },
  loadChildren: () =>
    import('@app/modules/dashboards/dashboards.module').then(
      (m) => m.DashboardsModule
    ),
};

const LOCATIONS_ROUTING: Route = {
  path: ROUTE_PATHS.LOCATIONS,
  canActivate: [AngularFireAuthGuard],
  resolve: { currentUserRoles: RoleAccessResolver },
  data: { authGuardPipe: privateAuth, access: ALL_MEMBERS },
  loadChildren: () =>
    import('@app/modules/locations/locations.module').then(
      (m) => m.LocationsModule
    ),
};

const REVIEWS_ROUTING: Route = {
  path: ROUTE_PATHS.REVIEWS,
  canActivate: [AngularFireAuthGuard],
  resolve: { currentUserRoles: RoleAccessResolver },
  data: { authGuardPipe: privateAuth, access: ALL_MEMBERS },
  loadChildren: () =>
    import('@app/modules/reviews/reviews.module').then((m) => m.ReviewsModule),
};
const BRANDS_ROUTING: Route = {
  path: ROUTE_PATHS.BRANDS,
  canActivate: [AngularFireAuthGuard],
  resolve: { currentUserRoles: RoleAccessResolver },
  data: {
    authGuardPipe: privateAuth,
    access: [
      UserAccessRolesEnum.COMPANY_ADMIN,
      UserAccessRolesEnum.BRAND_ADMIN,
    ],
  },
  loadChildren: () =>
    import('@app/modules/brands/brands.module').then((m) => m.BrandsModule),
};
const TAGS_ROUTING: Route = {
  path: ROUTE_PATHS.TAGS,
  canActivate: [AngularFireAuthGuard],
  resolve: { currentUserRoles: RoleAccessResolver },
  data: {
    authGuardPipe: privateAuth,
    access: [UserAccessRolesEnum.COMPANY_ADMIN],
  },
  loadChildren: () =>
    import('@app/modules/tags/tags.module').then((m) => m.TagsModule),
};
const TEAM_MANAGEMENT_ROUTING: Route = {
  path: ROUTE_PATHS.TEAM_MANAGEMENT,
  canActivate: [AngularFireAuthGuard],
  resolve: { currentUserRoles: RoleAccessResolver },
  data: {
    authGuardPipe: privateAuth,
    access: ALL_MEMBERS,
  },
  loadChildren: () =>
    import('@app/modules/team-management/team-management.module').then(
      (m) => m.TeamManagementModule
    ),
};

const SOCIAL_POSTING_ROUTING: Route = {
  path: ROUTE_PATHS.SOCIAL_POSTING,
  resolve: { currentUserRoles: RoleAccessResolver },
  data: { authGuardPipe: privateAuth, access: ALL_MEMBERS },

  loadChildren: () =>
    import('@app/modules/social-posting/social-posting.module').then(
      (m) => m.SocialPostingModule
    ),
};

const AUTH_ROUTING: Route = {
  path: ROUTE_PATHS.AUTH,
  loadChildren: () =>
    import('@app/modules/auth/auth.module').then((m) => m.AuthModule),
};

const ONBOARDING_ROUTING: Route = {
  path: ROUTE_PATHS.ON_BOARDING,
  canActivate: [AngularFireAuthGuard],
  data: { authGuardPipe: privateAuth },
  loadChildren: () =>
    import('@app/modules/onboarding/onboarding.module').then(
      (m) => m.OnboardingModule
    ),
};
const USER_PROFILE_ROUTING: Route = {
  path: ROUTE_PATHS.USER_PROFILE,
  canActivate: [AngularFireAuthGuard],
  resolve: { currentUserRoles: RoleAccessResolver },
  data: { authGuardPipe: privateAuth, access: ALL_MEMBERS },
  loadChildren: () =>
    import('@app/modules/user-profile/user-profile.module').then(
      (m) => m.UserProfileModule
    ),
};

const PLATFORM_DASHBOARD_ROUTING: Route = {
  path: ROUTE_PATHS.MAIN_DASHBOARD,
  canActivate: [AngularFireAuthGuard],
  resolve: { currentUserRoles: RoleAccessResolver },
  data: { authGuardPipe: privateAuth, access: ALL_MEMBERS },
  loadChildren: () =>
    import('@app/modules/platform-dashboard/platform-dashboard.module').then(
      (m) => m.PlatformDashboardModule
    ),
};

const COMPETITORS_ROUTING: Route = {
  path: ROUTE_PATHS.COMPETITORS,
  canActivate: [AngularFireAuthGuard],
  resolve: {
    currentUserRoles: RoleAccessResolver,
    planAccess: PlanAccessResolver,
  },
  data: {
    authGuardPipe: privateAuth,
    access: [
      UserAccessRolesEnum.COMPANY_ADMIN,
      UserAccessRolesEnum.BRAND_ADMIN,
    ],
    planAccess: [PlanTypeEnum.EXPERT],
  },
  loadChildren: () =>
    import('@app/modules/competitors/competitors.module').then(
      (m) => m.CompetitorsModule
    ),
};

const COMPANY_INFO_ROUTING: Route = {
  path: ROUTE_PATHS.COMPANY_INFO_ROUTING,
  canActivate: [AngularFireAuthGuard, PaymentGatewayAccessGuard],
  // resolve: {
  //   currentUserRoles: RoleAccessResolver,
  // },
  data: {
    authGuardPipe: privateAuth,
    access: [UserAccessRolesEnum.COMPANY_ADMIN],
    paymentGatewayAccess: PaymentGateway.UPG,
  },
  loadChildren: () =>
    import('@app/modules/company-info/company-info.module').then(
      (m) => m.CompanyInfoModule
    ),
};

const NO_PERMISSION_ROUTING: Route = {
  path: ROUTE_PATHS.NO_PERMISSION,
  pathMatch: 'full',
  canActivate: [AngularFireAuthGuard],
  data: {
    authGuardPipe: privateAuth,
  },
  component: NoPermissionComponent,
};

const routes: Routes = [
  DEFAULT_ROUTING,
  AUTH_ROUTING,
  PLATFORM_DASHBOARD_ROUTING,
  BI_DASHBOARD_ROUTING,
  ONBOARDING_ROUTING,
  SOCIAL_POSTING_ROUTING,
  LOCATIONS_ROUTING,
  REVIEWS_ROUTING,
  USER_PROFILE_ROUTING,
  BRANDS_ROUTING,
  TEAM_MANAGEMENT_ROUTING,
  TAGS_ROUTING,
  ADDONS_ROUTING,
  SUBSCRIPTION_ROUTING,
  COMPETITORS_ROUTING,
  COMPANY_INFO_ROUTING,
  NO_PERMISSION_ROUTING,
  NOT_FOUND_ROUTE,
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
