import { Component, Input } from '@angular/core';
type ITextTag = 'h1' | 'h2' | 'h3' | 'h5' | 'p' | 'small';
type ITextColor = 'inherit' | 'grey';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent {
  @Input({ required: true }) tag!: ITextTag;
  @Input() weight?: string;
  @Input() color?: ITextColor;
  @Input() classes?: string;
}
