import { ApplicationHttpClient } from '@app/helpers/custom-http-client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpResult } from '@app/shared/models/http-result';
import { map } from 'rxjs/operators';
import {
  AddonAvailabilityEnum,
  IActivateAddonPost,
  IAvailableAddonDetails,
  ICustomerProduct,
  IGetAllAddonsResponse,
  IDynamicProductCostResponse,
  ICustomerAddonSerialized,
  IAvailableAddonSerialized,
  IAddonSerializedCommonData,
  IAddonDetails,
  IAddonsSerialized,
  IAddonSerialized,
} from '../models/addons.model';

@Injectable({
  providedIn: 'root',
})
export class AddonsService {
  private subscriptionsURL = '/subscriptions';

  constructor(private http: ApplicationHttpClient) {}

  // #region APIs
  // GET /api/v1/subscriptions/customer-product/all/{companyId}
  public getAllAddons(companyId: string): Observable<IGetAllAddonsResponse> {
    const url = `${this.subscriptionsURL}/customer-product/all/${companyId}`;
    return this.http.get<HttpResult>(url).pipe(map((it) => it.data));
  }

  // POST /api/v1/subscriptions/start-product
  public startPayment(data: IActivateAddonPost): Observable<string> {
    const url = `${this.subscriptionsURL}/start-product`;
    return this.http.post<HttpResult>(url, data).pipe(map((it) => it.data));
  }

  // GET /api/v1/subscriptions/process-product
  public processPayment(): Observable<boolean> {
    const url = `${this.subscriptionsURL}/process-product${window.location.search}`;
    return this.http.get<HttpResult>(url).pipe(map((it) => it.data));
  }

  // POST /api/v1/subscriptions/start-topup-product
  public startTopupPayment(data: IActivateAddonPost): Observable<string> {
    const url = `${this.subscriptionsURL}/start-topup-product`;
    return this.http.post<HttpResult>(url, data).pipe(map((it) => it.data));
  }

  // GET /api/v1/subscriptions/process-topup-product
  public processTopupPayment(): Observable<boolean> {
    const url = `${this.subscriptionsURL}/process-topup-product${window.location.search}`;
    return this.http.get<HttpResult>(url).pipe(map((it) => it.data));
  }

  // GET /api/v1/companies/:companyId/product-cost?quantity=2&packageId={productPackageId}
  public getDynamicProductCost(
    companyId: string,
    productPackageId: string,
    quantity: number
  ): Observable<IDynamicProductCostResponse> {
    const url = `/companies/${companyId}/product-cost?quantity=${quantity}&packageId=${productPackageId}`;
    return this.http.get<HttpResult>(url).pipe(map((it) => it.data));
  }
  // #endregion APIs

  // #region Utils
  public addonNameToUrl(addonName: string): string {
    return addonName.toLowerCase().replace(/ /g, '-');
  }

  private serializeAddonCommonData(
    addon: IAddonDetails
  ): IAddonSerializedCommonData {
    return {
      addonId: addon.id,
      title: addon.title,
      icon: addon.icon,
      description: addon.description,
      short_description: addon.short_description,
      unit: addon.unit,
    };
  }

  public serializeCustomerAddon(
    customerAddon: ICustomerProduct
  ): ICustomerAddonSerialized {
    return {
      ...this.serializeAddonCommonData(customerAddon.package.product),
      type: customerAddon.package.product.type,
      active: customerAddon.package.product.active,
      availableQuota: customerAddon.availableQuota,
      currency: customerAddon.package.product.countryPricing.currency,
      quantity: customerAddon.quantity,
      status: customerAddon.status,
      addonAvailability: AddonAvailabilityEnum.ACTIVE_PRODUCT,
      productId: customerAddon.package.product.id,
      customerProductId: customerAddon.id,
      expiryDate: customerAddon.expiryDate,
      packageId: customerAddon.package.id,
      price: customerAddon.package.price,
      unit: customerAddon.package.product.unit,
      quota: customerAddon.package.quota,
      packagePlanType: customerAddon.package.package,
    };
  }

  public serializeAvailableAddon(
    availableAddon: IAvailableAddonDetails
  ): IAvailableAddonSerialized {
    return {
      ...this.serializeAddonCommonData(availableAddon),
      type: availableAddon.type,
      packages: availableAddon.packages,
      currency: availableAddon.countryPricing.currency,
      addonAvailability: AddonAvailabilityEnum.AVAILABLE_PRODUCT,
    };
  }

  public findInAllAddons(
    addons: IAddonsSerialized,
    key: keyof IAddonSerialized,
    value: string
  ): ICustomerAddonSerialized | IAvailableAddonSerialized | undefined {
    return (
      addons.serializedCustomerAddons.find(
        (addon) => addon[key].toLowerCase() === value.toLowerCase()
      ) ||
      addons.serializedAvailableAddons.find(
        (addon) => addon[key].toLowerCase() === value.toLowerCase()
      )
    );
  }
  // #endregion Utils
}
