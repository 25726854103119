import { Component, Input } from '@angular/core';
import { IIconType } from '@app/core/models/icon-list';

type ColorVariants = 'dark-blue' | 'black' | 'white';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input({ required: true }) iconName: IIconType = 'settings';
  @Input() width?: string;
  @Input() maxWidth?: string;
  @Input() height?: string;
  @Input() maxHeight?: string;
  @Input() fillSvgColor?: ColorVariants;
  @Input() fillPathColor?: ColorVariants;
}
