import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import {
  fetchBrands,
  fetchBrandsFailure,
  fetchBrandsSuccess,
  setSelectedBrand,
} from '@app/shared/store/brands/brands-actions';
import { UserService } from '@app/shared/services/user.service';
import { Brand } from '@app/shared/models/brand';
import { BrandsFacade } from './brands-facade';

@Injectable()
export class BrandsEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private brandsFacade: BrandsFacade
  ) {}

  fetchBrands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchBrands),
      switchMap(() =>
        this.userService.getMyBrands().pipe(
          map((it: Brand[]) => {
            return fetchBrandsSuccess({ brands: it });
          }),
          catchError((err) => {
            return of(fetchBrandsFailure({ error: err.message }));
          })
        )
      )
    )
  );
  
  fetchBrandsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fetchBrandsSuccess),
        withLatestFrom(this.brandsFacade.selectSelectedBrand$),
        switchMap((results) => {
          if (results[1].id == undefined) {
            return of(setSelectedBrand({ brand: results[0].brands[0] }));
          }
          return EMPTY;
        })
      ),
    { dispatch: true }
  );
}
