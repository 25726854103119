import { Component, Input } from '@angular/core';

type CardColor =
  | 'white'
  | 'white-bordered'
  | 'primary-8-percent'
  | 'primary-8-percent-bordered'
  | 'primary-25-percent'
  | 'primary-disabled'
  | 'green-8-percent'
  | 'green-8-percent-bordered';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() color: CardColor = 'white';
  @Input() hasError: boolean = false;
  @Input() padding: '20px' | '30px' | '40px' = '30px';
  @Input() classes: string = '';
  @Input() hasElevation: boolean = false;
}
